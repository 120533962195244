import React from 'react';

import { useTheme } from '../../context/MagicBellThemeContext';

/**
 * Default MacigBell icon for the notification launcher/toggler.
 *
 * @example
 * <BellIcon />
 */
export default function BellIcon() {
  const { icon: theme } = useTheme();

  return (
    <svg width={theme.width} height={theme.width} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5002 1.35008C14.8661 1.35476 16.1839 1.85467 17.2092 2.75708C18.2345 3.65948 18.8977 4.90313 19.0757 6.25733C19.0757 6.33833 19.0757 6.41933 19.0757 6.50033C19.0789 6.54978 19.0789 6.59938 19.0757 6.64883V6.75008C19.0757 6.83108 19.0757 6.91883 19.0757 6.99983V10.1251C19.159 12.2833 19.8161 14.3803 20.9792 16.2001C21.6542 17.4151 21.9512 17.9618 22.12 18.2251V18.2926H4.84673L4.88048 18.2251C5.04923 17.9483 5.34623 17.4083 6.00773 16.2001C7.15426 14.3755 7.7949 12.2789 7.86398 10.1251V6.98633C7.86398 6.90533 7.86398 6.82433 7.86398 6.73658V6.68258C7.86077 6.63313 7.86077 6.58353 7.86398 6.53408V6.53408C7.87283 6.44344 7.88634 6.35332 7.90448 6.26408C8.07875 4.90403 8.74341 3.65435 9.7737 2.74958C10.804 1.84482 12.1291 1.34718 13.5002 1.35008V1.35008ZM13.5002 8.14381e-05C11.8073 0.00217804 10.1728 0.619518 8.90126 1.73713C7.62966 2.85474 6.80762 4.39643 6.58823 6.07508C6.58823 6.18308 6.58823 6.28433 6.54773 6.39233C6.5413 6.47546 6.5413 6.55896 6.54773 6.64208C6.54117 6.76349 6.54117 6.88517 6.54773 7.00658V10.1251C6.46641 12.0445 5.8771 13.9079 4.83998 15.5251C3.72623 17.5501 3.69923 17.5501 3.69923 17.5501L2.94323 18.9001C2.88069 18.9728 2.84212 19.063 2.83278 19.1585C2.82344 19.2539 2.84379 19.3499 2.89105 19.4333C2.93831 19.5168 3.01018 19.5836 3.09684 19.6247C3.1835 19.6658 3.28072 19.6791 3.37523 19.6628H23.6252C23.7092 19.6655 23.7923 19.6453 23.8658 19.6046C23.9392 19.5638 24.0003 19.5039 24.0425 19.4313C24.0847 19.3586 24.1065 19.2759 24.1055 19.1919C24.1045 19.1079 24.0809 19.0257 24.037 18.9541L23.281 17.6041C23.281 17.6041 23.281 17.6041 22.1402 15.5791C21.113 13.9408 20.5421 12.0579 20.4865 10.1251V6.98633C20.493 6.86492 20.493 6.74324 20.4865 6.62183C20.4929 6.53871 20.4929 6.45521 20.4865 6.37208C20.4865 6.26408 20.4865 6.16283 20.446 6.05483C20.2227 4.3735 19.3942 2.83119 18.1157 1.71669C16.8372 0.602178 15.1963 -0.00811186 13.5002 8.14381e-05V8.14381e-05Z"
        fill={theme.borderColor}
      />
      <path
        d="M18.4544 23.2402C18.2681 23.5004 18.0628 23.7463 17.8401 23.976C17.2722 24.5502 16.5983 25.0087 15.8556 25.326C15.1152 25.6377 14.32 25.7982 13.5167 25.7982C12.7134 25.7982 11.9182 25.6377 11.1779 25.326C10.4352 25.0087 9.76121 24.5502 9.19335 23.976C8.96872 23.748 8.76327 23.502 8.5791 23.2402"
        stroke={theme.borderColor}
        strokeWidth="1.35"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
